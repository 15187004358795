body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%) !important;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa !important;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0168b3 !important;
  width: auto;
  flex-grow: 1;
}

.navbar-brand img {
  height: 65px;
}

.navbar-brand h3 {
  margin: 0 auto;
  font-size: 1.5rem;
  color: #0168b3 !important;
}


.container {
  padding: 20px;
}

.banner-card {
  background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%) !important;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.banner-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.banner-image-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.banner-image-container:hover .banner-image-overlay {
  opacity: 1;
}

.banner-image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.banner-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.banner-image-container:hover .banner-image-overlay {
  opacity: 0.5;
}

.welcome-text {
  font-size: 1.75rem;
  font-weight: 600;
  color: #0168b3;
  margin-bottom: 1rem;
}

.informative-text {
  font-size: 1rem;
  color: #2c2b2b;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.category-card {
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
}

.category-card:hover {
  transform: scale(1.1) !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}

.card-body {
  text-align: center;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #343a40;
}

.card-text {
  font-size: 1rem;
  color: #6c757d;
}

.card-overlay {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  transition: opacity 0.3s ease;
}

.category-card:hover .card-overlay {
  display: block;
  opacity: 1;
}

.btn-primary {
  background-color: #d1496d;
  border-color: #d1496d;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 1;
}

.btn-primary:hover {
  background-color: #a13352;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.text-center {
  text-align: center;
}


.details-page {
  height: 100vh;
  background-image: linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%);
}
